import React, { useState } from 'react';
import ApiService from '../Services/ApiService';

function Import() {

    const [fileSelected, setFileSelected] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Obtener el primer archivo seleccionado
        setFileSelected(file || null);
    };

    const onUpload = async () => {
        console.log(fileSelected);
        let { message } = await ApiService.uploadFile(fileSelected);
        alert(message);
    }

    return (
        <div className="container">

            <div className="row mt-5">
                <div className="col-6">
                    <div className="card mb-4 rounded-3 shadow-sm">
                        <div className="card-header py-3">
                            <h4 className="my-0 fw-normal">Upload zone</h4>
                        </div>
                        <div className="card-body">
                            <div className="mb-4 mt-2">
                                <label htmlFor="formFileSm" className="form-label small">Select a file to import</label>
                                <input className="form-control form-control-sm"
                                    onChange={handleFileChange}
                                    id="formFileSm" type="file" />
                            </div>

                            <button type="button"
                                onClick={onUpload}
                                disabled={!fileSelected}
                                className="w-100 btn btn-lg btn-outline-primary">Upload</button>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Import