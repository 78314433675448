import { Link } from "react-router-dom"
import ApiService from "../Services/ApiService";
import { useState, useEffect } from "react";
import moment from 'moment';

function Uploads() {

    let [uploads, setUploads] = useState([]); // state


    const getUploads = async () => {
        let uploads = await ApiService.getUploads();
        setUploads(uploads);
    }

    useEffect(() => {
        getUploads(); // Llama a getUploads cuando el componente se monta
    }, []);

    // getUploads();

    const renderUploads = () => {
        return uploads.map((upload, index) => (
            <div className="card mb-4">
                <div className="card-body">
                    <h5>Upload #{index + 1}
                    </h5>
                    <div className="mb-3">
                        <i className="small text-muted">
                            <i className="far fa-file-alt"></i> {upload.filename}
                        </i>
                    </div>
                    <div className="d-flex small">
                        <div>
                            <i class="fas fa-circle text-warning me-1"></i>
                            <i>Emails To Check:</i> <b>{upload.emails_to_check}</b>
                        </div>
                        <div>
                            <i class="fas fa-circle text-info me-1 ms-3"></i>
                            <i>Emails Checked:</i> <b>{upload.emails_checked}</b>
                        </div>
                        <div>
                            <i class="fas fa-circle text-success me-1 ms-3"></i>
                            <i>Emails Valid: </i> <b>{upload.emails_safe}</b>
                        </div>
                        <div>
                            <i class="fas fa-circle text-danger me-1 ms-3"></i>
                            <i>Emails Invalid:</i> <b>{upload.emails_bounced}</b>
                        </div>
                    </div>

                    <hr />

                    <div className="d-flex justify-content-between small">
                        <div>
                            <Link className="fs-14" to={'/uploads/' + upload._id}>
                                <i class="fas fa-info-circle me-1"></i>
                                View details
                            </Link>
                            <a
                                href={'https://api-core.iworker.co/api/nobouncy/uploads/download/' + upload._id}
                                className="btn btn-link fs-14 ms-2">
                                <i class="fas fa-file-csv me-1"></i>

                                Download CSV
                            </a>
                        </div>



                        <div className="small text-muted">
                            <i class="far fa-clock me-2"></i>
                            {moment(upload.created_at).format('lll')}
                        </div>

                    </div>
                </div>

            </div>
        ));
    }

    return (
        <div>
            <div className="container">

                <h3 className="mb-4 mt-5">Uploads</h3>

                {renderUploads()}

            </div>


        </div>

    )
}

export default Uploads