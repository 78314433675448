import logo from './logo.svg';
import './App.css';
import Sidebar from './Sidebar/Sidebar';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Dashboard from './Dashboard/Dashboard';
import Import from './Import/Import';
import Results from './Results/Results';
import Uploads from './Uploads/Uploads';
import UploadDetail from './Uploads/UploadDetail';
import Messages from './Messages/Messages';

function App() {
  return (
    <main>
      <BrowserRouter>
        <Sidebar />
        <div style={{ flex: 1, background: '#F4F7FA', overflowY: 'scroll' }}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="import" element={<Import />} />
            <Route path="uploads/:id" element={<UploadDetail />} />
            <Route path="uploads" element={<Uploads />} />
            <Route path="messages" element={<Messages />} />
          </Routes>
        </div>

      </BrowserRouter>

    </main >
  );
}

export default App;
