import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import ApiService from '../Services/ApiService';
import moment from 'moment';

const UploadDetail = () => {
    const { id } = useParams();

    let [emails, setEmails] = useState([]);

    const getUploadDetail = async () => {
        emails = await ApiService.getUploadDetail(id);
        setEmails(emails);
    }

    useEffect(() => {
        getUploadDetail();
    })

    const renderBounceStatus = (status) => {
        if(status === 'valid') {
            return <i class="fas fa-check text-success"></i>
        }else {
            return <i class="fas fa-times text-danger"></i>
        }
    }

    return (
        <div>
            <div className='container mb-5 mt-5'>

                <h3 className='mb-4'>Details</h3>
                <div className="card">

                    <div className="card-body">

                        <h5>Emails (<span className='fs-14 text-danger'>{emails.length}</span>)</h5>

                        <table className='table table-responsive table-bordered  small'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Email</th>
                                    <th>Initial Email Sent</th>
                                    <th>Initial Email Sent at</th>
                                    <th>Is Valid?</th>
                                    <th>Bounce checked at</th>
                                </tr>
                            </thead>
                            <tbody>
                                {emails.map((email, index) => (
                                    <tr key={email.id}>
                                        <td>{index + 1}</td>
                                        <td>{email.email}</td>
                                        <td>{email.initial_email_sent ? 'Yes' : 'No'}</td>
                                        <td>{moment(email.initial_email_sent_at).format('lll')}</td>
                                        <td>{renderBounceStatus(email.bounce_status)}</td>
                                        <td>{moment(email.verified_at).format('lll')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default UploadDetail;

