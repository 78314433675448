import Requests from "./Requests";

const API_URL = 'https://api-core.iworker.co/api';
// const API_URL = 'http://localhost:3000/api';

const UPLOAD_CSV_EMAILS = '/nobouncy/upload/csv';
const GET_UPLOADS = '/nobouncy/uploads';
const GET_UPLOAD_DETAIL = '/nobouncy/uploads/detail';

const GET_MESSAGES = API_URL + '/nobouncy/messages/get';
const SAVE_MESSAGE = API_URL + '/nobouncy/messages/save';
const UPDATE_MESSAGE = API_URL + '/nobouncy/messages/update';

const START_INITIAL_TIMER = API_URL + '/nobouncy/timer/initial';
const START_BOUNCE_TIMER = API_URL + '/nobouncy/timer/bounce';

const GET_LATEST_INITIAL_EMAIL_SENT = API_URL + '/nobouncy/emails/initial/latest';
const GET_LATEST_TIMERS_LOGS = API_URL + '/nobouncy/timer/logs';

const ApiService = {

    uploadFile: (file) => {
        const formData = new FormData();
        formData.append('file', file);

        return fetch(API_URL + UPLOAD_CSV_EMAILS, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => data)
            .catch(error => error);
    },
    getUploads: () => {
        return Requests.get(API_URL + GET_UPLOADS);
    },
    getUploadDetail(id) {
        return Requests.get(API_URL + GET_UPLOAD_DETAIL + '/' + id);
    },
    getMessages: () => {
        return Requests.get(GET_MESSAGES);
    },
    saveMessage: (message) => {
        return Requests.post(SAVE_MESSAGE, message);
    },
    updateMessage: (message) => {
        return Requests.put(UPDATE_MESSAGE, message);
    },
    startInitialTimer: () => {
        return Requests.post(START_INITIAL_TIMER, {})
    },
    startBounceTimer: () => {
        return Requests.post(START_BOUNCE_TIMER, {})
    },
    getLatestInitialEmailsSent: () => {
        return Requests.get(GET_LATEST_INITIAL_EMAIL_SENT);
    },
    getLatestTimersLogs: () => {
        return Requests.get(GET_LATEST_TIMERS_LOGS);
    }

}

export default ApiService;